<template>
	<main id="main-container" class="main-container">
		<div
			class="hero hero--1 slider-dot-fix slider-dot slider-dot-fix slider-dot--center slider-dot-size--medium slider-dot-circle  slider-dot-style--fill slider-dot-style--fill-white-active-white dot-gap__X--10">
			<div class="hero-slider"><img src="assets/picture/shouye1.png" alt="">
				<div class="hero__content">
					<div class="container">
						<div class="row">
							<div class="col-8 col-lg-8 offset-lg-1 offset-xxl-0 col-xl-6  col-xxl-6">
								<div class="hero__content--inner">
									<h1 class="hero-title-1 title title--large title--white text-uppercase">Mellow TASTE</h1>
									<h1 class="hero-title-2 title title--large title--white font--bold text-uppercase">
										ELEGANT TASTE</h1>
									<h6 class="hero-title-3 title title--small title--white pos-relative" style="padding-left: 0px;">
										<!-- <span class="border-line--small pos-absolute"></span> -->
										<p style="font-size: 30px;font-family: Source Han Sans SC VF;font-weight: 350;">精选高品质红酒</p>
										<p style="font-family: Source Han Sans SC VF;font-size: 20px;font-style: normal;font-weight: 400;">A selection of high quality wines</p>
									</h6><a href="/shopmall"
										class="hero-btn-link btn btn--small btn--white btn--white-hover-black font--bold text-uppercase">开始购物</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="cms cms--1 m-t-100">
			<div class="container">
				<div class="row">
					
					<div class="col-lg-6 col-sm-6 col-12">
						<div class="cms__content">
							<div class="cms__icon"><img class="cms__icon-img" src="assets/picture/icon3.png" alt="">
							</div>
							<div class="cms__text">
								<h6 class="cms__title">安全支付</h6><span class="cms__des">使用世界上最安全的支付方式进行支付</span>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-sm-6 col-12">
						<div class="cms__content">
							<div class="cms__icon"><img class="cms__icon-img" src="assets/picture/icon4.png" alt="">
							</div>
							<div class="cms__text">
								<h6 class="cms__title">优质货源</h6><span class="cms__des">拥有便宜优质的多种货源，让客户获得良好的体验</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="product m-t-100">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="section-content m-b-35 text-center">
							<h5 class="section-content__title">热门商品</h5><router-link to="/shopmall"
								class="section-content__desc">查看所有商品</router-link>
						</div>
					</div>
				</div>
				<div class="row">
						<div class="clearfix">
							<div v-for="(item,index) in list" :key="index" class="product__box product__box--catagory product__box--border-hover text-center pos-relative">
								<div class="product__img-box">
									<router-link :to="'/productdetails?id='+item.id"  class="product__img--link  glossy-flash-animate">
										<img class="product__img" :src="'/XYZ/systemConfig/static/'+item.imgName" alt="" >
									</router-link>
								</div>
								<div class="product__content--catagory pos-absolute">
									<router-link :to="'/productdetails?id='+item.id" class="btn btn--box btn--tiny btn--gray btn--gray-hover-red font--bold text-uppercase">{{ item.name }}</router-link>
								</div>
							</div>
							
						</div>
					</div>
			</div>
		</div>
		
	</main>
</template>

<script>
import { getAction,postAction } from '@/utils/manage'
export default {
	name: 'index',
	data() {
		return {
			list:[],
		}
	},
	methods: {
		getMaterialList(){
			var that = this;
			postAction('/client/mall/material/getMaterialHeatList', {
					"page": {
						"size": 4,
						"current": 1
					}
				}).then((res) => {
				if(res.code == 200){
					that.list = res.data;
				}
			}).finally(() => {
				
			});
		},
	},
	created() {
		this.getMaterialList()
	},
}
</script>

<style scoped>
.product__img-box{
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 115%;
	overflow: hidden;
}
.product__img{
	position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  max-width: 100%; /* 防止图片宽度超过父容器 */
  transform: translate(-50%, -50%);
}
</style>