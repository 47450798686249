<template>
	<div class="page-breadcrumb"  style="padding: 41px; margin-bottom: 50px">
		
	</div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="row text-center bbtn-group">
						<div class="col-6 bbtn bbtn-left" @click="getCollectiveTemplate()">下载集采模板</div>
						<div class="col-6 bbtn bbtn-right" @click="uploadOrder()">上传集采订单</div>
						<input type="file" id="fileInputOrder" style="display: none;" />
					</div>
					<order-list-com orderType="JC"></order-list-com>
					<div v-if="false" class="table-content table-responsive cart-table-content m-t-30">
						<table style="width: 100%;">
							<thead>
								<tr>
									<!-- <th>图片</th> -->
									<th style="width: 10%;">单号</th>
									<th style="width: 10%;">采购类型</th>
									<th style="width: 10%;">联系人</th>
									<th style="width: 10%;">联系电话</th>
									<th style="width: 30%;">地址</th>
									<th style="width: 10%;">总价</th>
									<th style="width: 5%;">状态</th>
									<th style="width: 25%;">操作</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in records" :key="index">
									<!-- <td class="product-thumbnail"><a href="#"><img class="img-fluid" src="assets/picture/cart-1.jpg"
												alt=""></a></td> -->
									<td class="product-name">{{item.number}}</td>
									<td class="product-name">{{item.oneClickShipping == 1 ? '代发' : '批发'}}</td>
									<td class="product-name">{{item.linkName}}</td>
									<td class="product-price-cart"><span class="amount">{{item.linkTel}}</span></td>
									<td class="product-price-cart"><span class="amount">{{item.address}}</span></td>
									<!-- <td class="product-quantity">
										<div class="quantity d-inline-block"><input type="number" min="1" step="1" value="1"></div>
									</td> -->
									<td class="product-subtotal">￥{{item.totalPrice}}</td>
									<td class="product-subtotal">{{item.statusStr}}</td>
									<td class="product-name">
										<button  v-if="item.status==-1" @click="getDht(item.id)" class="an btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">下载模板</button>
										<button v-if="item.status>-1 && item.fileName" @click="seeHt(item.fileName)" class="an btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">查看合同</button>
										<button v-if="item.status==-1" @click="uploadFile(item.id,1)" class="an btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">上传合同</button>
										<input type="file" id="fileInput" style="display: none;" />
										<button v-if="item.status==3 || ( item.status==7 && ( item.payType == 'WEEK' || item.payType == 'MONTH' || item.oneClickShipping == 1 ) )" @click="uploadFile(item.id, 3)" class="an btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">上传支付凭证</button>
										<button v-if="item.status==6" @click="confirmReceipt(item.id)" class="an btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">确认收货</button>
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</main>
	<el-dialog
    v-model="dialogVisible"
    title="预览"
    :before-close="handleClose"
  >
		<vue-office-docx v-if="filetype=='docx'" :src="fileurl" @rendered="rendered"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="xzFile()">
          下载
        </el-button>
      </span>
    </template>
  </el-dialog>
	<el-dialog title="上传集采订单" v-model="dialogTableVisible" draggable overflow width="800">
		<el-upload
			class="upload-demo"
			drag
			action="/XYZ/client/mall/businessOrderInfo/clientImportOrder"
			:headers="headersObj"
		>
			<el-icon class="el-icon--upload"><upload-filled /></el-icon>
			<div class="el-upload__text">
				拖动文件到这 或 <em>点击上传</em>
			</div>
			<template #tip>
				<div class="el-upload__tip">
					请上传下载的EXCEL模板
				</div>
			</template>
		</el-upload>
		<el-row justify="end">
			<el-button type="primary" size="large">提交订单</el-button>
		</el-row>
		
	</el-dialog>
</template>

<script>
	import OrderListCom from '@/components/OrderListCom'
	export default {
	  name: 'OrderListFocus',
		data() {
			return {
				
			}
		},
		components:{
			OrderListCom
		},
		methods: {
			uploadOrder:function(){
				this.$router.push({
					path: '/checkout',
					query: {
						isUpload: 1,
						oneClickShipping: 0
					}
				})
			},
			getCollectiveTemplate:function(){
				// window.open('/XYZ/client/mall/businessOrderInfo/importOrderTemplate')
				location.href = '/assets/template/CXDD.xls'
			},
		},
		mounted() {
			
	  },
	}
</script>

<style scoped>
.an{
	margin-left: 10px;
	margin-top: 5px;
	width: 8rem !important;
}
table{
	border: 0;
}
.tb-item{
	font-size: 15px;
	line-height: 25px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap; /* 允许换行 */
}
.tb-item-text{
	width: 100%;
}
.bbtn{
	line-height: 60px;
	padding-left: 0;
	padding-right: 0;
}
.bbtn:hover{
	box-shadow: 0px 10px 10px rgb(156, 156, 156, .5);
	font-size: 45px;
	transition-duration: 300ms;
}
.bbtn-left:hover{
	border-radius: 10px 0px 0px 10px;
	background-color: red; /* 按钮颜色 */
  border: none;
  color: white; /* 文字颜色 */
  text-decoration: none;
  display: inline-block;
  font-size: 35px; /* 字体大小 */
  cursor: pointer; /* 鼠标光标变为点击手势 */
}
.bbtn-right:hover{
	border-radius: 0 10px 10px 0;
	background-color: red; /* 按钮颜色 */
  border: none;
  color: white; /* 文字颜色 */
  text-decoration: none;
  display: inline-block;
  font-size: 35px; /* 字体大小 */
  cursor: pointer; /* 鼠标光标变为点击手势 */
}
.bbtn-group{
	font-size: 30px;
	font-weight: 700;
	margin-right: 0;
	margin-left: 0;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
}
.bbtn-left{
	border-right: 1px solid #e0e0e0;
}
</style>