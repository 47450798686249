<template>
	<footer class="footer" style="margin-top: 100px;">
		<div class="container">
			<div class="footer__top">
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="footer__menu">
							<p style="font-size: 16px;color: white;letter-spacing: 2px;">奥姿佳品（武汉）国际贸易有限公司，是一家集自营集采平台和国内外贸易“一站式”供应链金融解决方案的公司。</p>
							<p style="font-size: 16px;color: white;letter-spacing: 2px;">AUSSIE PERMIUM GOODS(Wuhan) PTY LTD,  is a company that integrates self-operated gathering platform and "one-stop" supply chain finance solutions for domestic and foreign trade.</p>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="footer__about" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
							<div class="footer__logo"><router-link to="/" class="footer__logo-link"><img style="width: 100%;height: auto;"
										src="assets/picture/logo1.png" alt="" class="footer__logo-img"></router-link>
							</div>
							
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12" style="color: white;">
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-phone-alt" ></i></div>
							<div class="contact-info-dec"><a style="color: white;" href="tel://139 9559 9146">139 9559 9146</a><a style="color: white;" 
									href="tel://155 2768 6267">155 2768 6267</a></div>
						</div>
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-globe-asia"></i></div>
							<div class="contact-info-dec"><a style="color: white;" href="mailto://miaoqin@aozijiapin.com">miaoqin@aozijiapin.com</a><a style="color: white;"
									href="mailto://haolong@aozijiapin.com">haolong@aozijiapin.com</a></div>
						</div>
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-map-marker-alt"></i></div>
							<div class="contact-info-dec"><span style="color: white;">湖北省武汉市洪山区高新大道776号</span><span style="color: white;">华翔中心10楼</span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer__bottom">
				<div class="row">
					<div class="col-lg-8 col-md-6 col-12">
						<div class="footer__copyright-text">
							<p><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鄂ICP备2024032441号</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
	
</template>

<script>
</script>

<style>
</style>