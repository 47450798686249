<template>
	<div class="page-breadcrumb"  style="padding: 41px;">
		<!-- <div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="page-breadcrumb__menu">
						<li class="page-breadcrumb__nav"><router-link to="/">首页</router-link></li>
						<li class="page-breadcrumb__nav active">登录</li>
					</ul>
				</div>
			</div>
		</div> -->
	</div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section-content m-b-40">
						<h5 class="section-content__title text-center">修改密码</h5>
					</div>
				</div>
				<div class="offset-lg-3 col-lg-6 col-12">
					<div class="login-form-container">
						<!-- <h5 class="sidebar__title">登录</h5> -->
						<div class="login-register-form">
							<form action="javascript:;" method="post">
								<div class="form-box__single-group"><label for="form-username-password">原密码 *</label>
									<div class="password__toggle">
										<input  v-model="oldPassword" type="password" id="form-username-password" placeholder="输入原密码" required><span data-toggle="#form-username-password" class="password__toggle--btn fa fa-fw fa-eye"></span></div>
								</div>
								<div class="form-box__single-group"><label for="form-username-password">新密码 *</label>
									<div class="password__toggle">
										<input  v-model="newPassword" type="password" id="form-username-password" placeholder="输入新密码" required  @input="validatePassword"><span data-toggle="#form-username-password" class="password__toggle--btn fa fa-fw fa-eye"></span>
									</div>
									<p v-if="passwordError" style="color: red;">{{ passwordErrorVal }}</p>
								</div>
								<div class="form-box__single-group"><label for="form-username-password">重复新密码 *</label>
									<div class="password__toggle">
										<input  v-model="confirmPassword" type="password" id="form-username-password" placeholder="输入新密码" required><span data-toggle="#form-username-password" class="password__toggle--btn fa fa-fw fa-eye"></span>
									</div>
									<p v-if="confirmPasswordError" style="color: red;">{{ confirmPasswordErrorVal }}</p>
								</div>
								<button @click="login" class="btn btn--box btn--small btn--radius btn--black btn--black-hover-red btn--uppercase font--semi-bold m-tb-20" type="submit">修改</button>
							</form>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</main>
</template>

<script>
	import { postAction } from '@/utils/manage'
	export default {
	  name: "login",
	  data(){
	    return{
	      oldPassword:'',
				newPassword:'',
				confirmPassword:'',
				passwordError: false,
				confirmPasswordError: false,
				passwordErrorVal: "密码至少8位，且必须包含数字和字母",
				confirmPasswordErrorVal: "两次输入的密码不一致"
	    }
	  },
		watch: {
			confirmPassword(newVal) {
				if (newVal && newVal !== this.newPassword) {
					this.confirmPasswordError = true;
				} else {
					this.confirmPasswordError = false;
				}
			},
		},
		methods: {
			validatePassword() {
				const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
				this.passwordError = !passwordPattern.test(this.newPassword);
			},
			login(){
				var that = this;
				that.validatePassword();
				if (that.confirmPassword !== that.newPassword) {
					that.confirmPasswordError = true;
				} else {
					that.confirmPasswordError = false;
				}
				console.log(that.confirmPasswordError)
				console.log(that.passwordError)
				if(that.passwordError || that.confirmPasswordError){
					toastr.error("请填写正确信息！")
					return
				}
				
				postAction('/client/mall/user/updatePassword', {
					oldPassword:that.oldPassword,
					newPassword:that.newPassword
				}).then((res) => {
				  if(res.code == 200){
						toastr.success("修改成功！")
						// localStorage.removeItem('atoken')
						// localStorage.removeItem('supplier')
						// window.location.href = '/login';
					}else{
						toastr.error(res.data.message)
					}
				}).finally(() => {
				  
				});
			}
		},
		mounted: function() {
			
		}
	}
</script>

<style>
</style>