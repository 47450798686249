<template>
	<div class="page-breadcrumb"  style="padding: 41px;">
		
	</div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section-content">
						<h5 class="section-content__title">我的订单</h5>
					</div>
					<order-list-com ></order-list-com>
					
				</div>
			</div>
		</div>
	</main>
	
</template>

<script>
	import OrderListCom from '@/components/OrderListCom'
	export default {
	  name: 'OrderList',
		data() {
			return {
				
			}
		},
		components:{
			OrderListCom
		},
		methods: {
			
		},
		mounted() {
			
	  },
	}
</script>

<style>
.an{
	margin-left: 10px;
	margin-top: 5px;
	width: 8rem !important;
}
table{
	border: 0;
}
.tb-item{
	font-size: 15px;
	line-height: 25px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap; /* 允许换行 */
}
.tb-item-text{
	width: 100%;
}


</style>