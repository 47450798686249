import Vue from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL: '/XYZ'
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
let apiBaseUrl = "/XYZ";
//console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 300000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = localStorage.getItem('atoken')
    switch (error.response.status) {
      case 403:
        
        break
      case 500:
        if(token && data.message==="未登录"){
          localStorage.removeItem('atoken')
          localStorage.removeItem('supplier')
        }
				toastr.error("网络请求失败！")
        break
      case 404:
          
        break
      case 504:
        
        break
      case 401:
				localStorage.removeItem('atoken')
				localStorage.removeItem('supplier')
        if(data.path != "/XYZ/client/mall/user/userInfo"){
          try {
            ElMessage({
              offset: 160,
              message: '请先登录！',
              type: 'warning',
            })
            setTimeout(() => {
              window.location.href="/login"
            }, 1500)
          }catch (e) {
            window.location.href="/"
          }
        }
        break
      default:
        
        break
    }
  }
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  const token = localStorage.getItem('atoken')
  if (token) {
    config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
},(error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
    return response.data
  }, err)



export {
  service as axios
}