<template>
	<div class="page-breadcrumb"  style="padding: 41px;"></div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-lg-7">
					<div class="section-content">
						<h5 class="section-content__title">账单明细</h5>
					</div>
					<div style="margin-top: 40px;">
						<el-form
							ref="ruleFormRef"
							:model="formD"
							:rules="rules"
							label-width="auto"
							class="demo-ruleForm"
							size="large"
							status-icon
							:inline="true"
							label-position="top"
						>
							<el-row style="width: 100%;font-size: 14px;font-weight: 600;color: black;">
								<el-col :span="8">
									<el-form-item label="姓名" prop="linkName">
										<el-input v-model="formD.linkName" />
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="电话" prop="linkTel">
										<el-input v-model="formD.linkTel" />
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="交货日期" prop="deliveryData">
										<el-date-picker
											v-model="formD.deliveryData"
											type="date"
											placeholder="请选择日期"
											clearable
											format="YYYY/MM/DD"
											value-format="YYYY-MM-DD"
										/>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row style="width: 100%;font-size: 14px;font-weight: 600;color: black;">
								<el-col :span="24">
									<el-form-item label="收货地址" prop="address">
										<el-input v-model="formD.address" />
									</el-form-item>
								</el-col>
							</el-row>
							<el-row style="width: 100%;font-size: 14px;font-weight: 600;color: black;">
								<el-col :span="24">
									<el-form-item label="备注" prop="customerRemarks">
										<el-input v-model="formD.customerRemarks" type="textarea" rows="5"/>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</div>
					<!-- <form action="javascript:;" method="post" class="form-box">
						<div class="row">
							<div class="col-md-6">
								<div class="form-box__single-group"><label for="form-first-name">姓名</label><input v-model="formD.linkName" type="text"
										id="form-first-name"></div>
							</div>
							<div class="col-md-6">
								<div class="form-box__single-group"><label for="form-last-name">电话</label><input v-model="formD.linkTel" type="text"
										id="form-last-name"></div>
							</div>
							<div class="col-md-12">
								<div class="form-box__single-group"><label for="form-address-1">收货地址</label><input v-model="formD.address"
										type="text"  placeholder=""></div>
							</div>
							
							<div class="col-md-12">
								<div class="form-box__single-group">
									<label for="form-additional-info">备注</label><textarea v-model="formD.customerRemarks"
										id="form-additional-info" rows="5"
										placeholder=""></textarea>
								</div>
							</div>
							
						</div>
					</form> -->
				</div>
				<div class="col-lg-5">
					<div class="your-order-section">
						<div class="section-content">
							<h5 class="section-content__title">您的订单</h5>
						</div>
						<div class="your-order-box gray-bg m-t-40 m-b-30">
							<div class="your-order-product-info">
								<div class="your-order-top d-flex justify-content-between">
									<h6 class="your-order-top-left font--bold">产品</h6>
									<h6 class="your-order-top-right font--bold">总计</h6>
								</div>
								<ul class="your-order-middle">
									<li v-for="(item,index) in records" :key="index" class="d-flex justify-content-between">
										<span class="your-order-middle-left font--semi-bold">{{item.materialName}} <span style="color: red;">X {{item.quantity}}</span> {{ item.sku? ' —— '+item.sku:'' }}</span>
										<span class="your-order-middle-right font--semi-bold">￥{{item.price}}</span>
									</li>
									
								</ul>
								<div class="your-order-bottom d-flex justify-content-between">
									<h6 class="your-order-bottom-left font--bold">运输</h6><span
										class="your-order-bottom-right font--semi-bold">物流</span>
								</div>
								<div class="your-order-total d-flex justify-content-between">
									<h5 class="your-order-total-left font--bold">总价</h5>
									<h5 class="your-order-total-right font--bold">￥{{totle}}</h5>
								</div>
								<div class="payment-method">
									<div class="payment-accordion element-mrg">
										<div class="panel-group" id="accordion">
											<div v-for="(item,index) in payTypes" :key="index" class="panel payment-accordion">
												<div class="panel-heading" id="method-one">
													<h4 class="panel-title"><a
															class="collapsed d-flex justify-content-between align-items-center"
															data-toggle="collapse" data-parent="#accordion" 
															aria-expanded="false">{{ item.v }} <input class="form-check-input" type="radio" name="payType" :value="item.k" v-model="formD.payType"></a></h4>
												</div>
												<div id="method1" class="panel-collapse collapse">
													<div class="panel-body">
														<p>Please send a check to Store Name,Store Street,Store Town,Store State / County,Store
															Postcode.</p>
													</div>
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
						<button @click="placeOrder" :disabled="disabled" class="btn btn--block btn--small btn--radius btn--red btn--red-hover-black btn--uppercase font--bold"
							type="submit">提交订单</button>
					</div>
				</div>
			</div>
		</div>
	</main>
	<el-dialog title="上传集采订单" v-model="isUpload" draggable overflow width="800">
		<el-upload
			class="upload-demo"
			ref="upload"
			drag
			action="/XYZ/client/mall/businessOrderInfo/clientImportOrder"
			:headers="headersObj"
			:limit="1"
			:on-exceed="handleExceed"
			:on-success="handleSuccess"
			:auto-upload="false"
			v-model:file-list="fileList"
		>
			<el-icon class="el-icon--upload"><upload-filled /></el-icon>
			<div class="el-upload__text">
				拖动文件到这 或 <em>点击上传</em>
			</div>
			<template #tip>
				<div class="el-upload__tip">
					请上传下载的EXCEL模板
				</div>
			</template>
		</el-upload>
		<el-row justify="end" style="margin-top: 10px;">
			<el-button @click="submitUpload" type="primary" size="large">确定上传</el-button>
		</el-row>
		
	</el-dialog>
</template>

<script>
	import { getAction,postAction } from '@/utils/manage'
	export default {
	  name: 'CheckOut',
		data() {
			return {
				records:{},
				totle:0,
				formD:{
					address:'',
					byCart:'Y',
					linkName:'',
					linkTel:'',
					customerRemarks:'',
					detailDtoList:[],
					payType: 'CASH',
					oneClickShipping: 0,
					key: '',
					commitType: '',
				},
				payTypes: [],
				disabled: false,
				isUpload:0,
				fileList: [],
				headersObj: {
					'X-Access-Token': localStorage.getItem('atoken')
				},
				rules: {
					linkName: [
						{ required: true, message: '请输入姓名', trigger: 'blur' },
					],
					linkTel: [
						{
							required: true,
							validator: this.validatePhone,
							trigger: 'blur'
						},
					],
					deliveryData: [
						{ required: true, message: '请选择交货日期', trigger: 'blur' },
					],
					address: [
						{ required: true, message: '请填写收货地址', trigger: 'blur' },
					],
				},
				phoneRegex: /^(\+\d{1,4})?(((13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|17[0|1|3|5|6|7|8]|18[0-9]|19[8|9])\d{8})|(0\d{2,3}-?\d{7,8})|(0\d{2,3}-?\d{7,8}\-\d{1,4}))$/,
			}
		},
		created()
		{
			this.getPayTypes()
		},
		methods: {
			validatePhone(rule, value, callback) {
				if (!this.phoneRegex.test(value)) {
					callback(new Error('请输入正确的电话号码（包括手机和座机）'));
				} else {
					callback();
				}
			},
			handleSuccess(response, file, fileList) {
				if(response.code == 200){
					this.totle = response.data.totalPrice
					this.records = response.data.depotItemList;
					this.formD.key = response.data.key;
					this.formD.commitType = 1;
					for(var i=0;i<this.records.length;i++){
						if(this.records[i]['price'] == '' || this.records[i]['price'] == null){
							this.records[i]['price'] = 0
						}
					}
					this.isUpload = 0
				}else{
					this.fileList = []
					toastr.error(response.data)
				}
			},
			submitUpload() {
				if (this.fileList.length > 0) {
					this.$refs.upload.submit();
				} else {
					toastr.warning('请先选择要上传的文件');
				}
			},
			handleExceed(files, fileList) {
				// 或者你想保留最新的文件，可以这样操作：
				fileList.pop(); // 删除队列中最后一个文件（旧文件）
				fileList.push(...files); // 添加新的文件到队列
			},
			getPayTypes(){
				var that = this;
				getAction('/client/mall/user/userInfo').then((res) => {
				  if(res.code == 200){
						let payTypes = res.data.payType
						let payTypesArray = payTypes.split(",")
						let payTypesObj = []
						for(let i=0;i<payTypesArray.length;i++){
							let ppay = {}
							ppay.k = payTypesArray[i]
							if(payTypesArray[i] == 'CASH'){
								ppay.v = '银行直接转款'
							}
							if(payTypesArray[i] == 'WEEK'){
								ppay.v = '周结'
							}
							if(payTypesArray[i] == 'MONTH'){
								ppay.v = '月结'
							}
							payTypesObj.push(ppay)
						}
						this.payTypes = payTypesObj
					}
				}).finally(() => {
				  
				});
			},
			myCart(){
				var that = this;
				postAction('/client/mall/businessShoppingCart/myCart', {
						"size": 100,
						"current":1,
						"oneClickShipping": that.formD.oneClickShipping
					}).then((res) => {
				  if(res.code == 200){
						that.totle = res.data.totalPrice
						that.records = res.data.mallBusinessShoppingCartVoIPage.records;
						that.formD.detailDtoList = res.data.mallBusinessShoppingCartVoIPage.records;
						for(var i=0;i<that.records.length;i++){
							if(that.records[i]['price'] == '' || that.records[i]['price'] == null){
								that.records[i]['price'] = 0
							}
						}
					}
				}).finally(() => {
				  
				});
			},
			getDht(){
				window.open('/XYZ/client/mall/businessOrderInfo/dht?id=1744975224930377733')
				
			},
			placeOrder(){
				var that = this;
				this.$refs.ruleFormRef.validate((valid) => {
					if (valid) {
						postAction('/client/mall/businessOrderInfo/placeOrder', that.formD).then((res) => {
							if(res.code == 200){
								toastr.success("提交成功！")
								this.$router.push('/orderlist')
							}else{
								toastr.error(res.data.message)
							}
							that.disabled = false
						}).finally(() => {
							that.disabled = false
						});
					} else {
						console.log('表单验证失败');
						return false;
					}
				});
				return
				var that = this;
				that.disabled = true
				if(!that.formD.linkName){
					toastr.error("姓名不能为空！")
					that.disabled = false
					return
				}
				if(!that.formD.linkTel){
					toastr.error("电话不能为空！")
					that.disabled = false
					return
				}
				const phoneRegex = /^(\+86)?1[3-9]\d{9}$/; // 手机号码正则
				const telRegex = /^(\d{3,4}-)?\d{7,8}$/; // 座机号码正则
				
				if (!phoneRegex.test(that.formD.linkTel) && !telRegex.test(that.formD.linkTel)) {
					toastr.error("请输入有效的电话号码")
					that.disabled = false
					return
				}
				if(!that.formD.address){
					toastr.error("收货地址不能为空！")
					that.disabled = false
					return
				}
				postAction('/client/mall/businessOrderInfo/placeOrder', that.formD).then((res) => {
				  if(res.code == 200){
						toastr.success("提交成功！")
						this.$router.push('/orderlist')
					}else{
						toastr.error(res.data.message)
					}
					that.disabled = false
				}).finally(() => {
				  that.disabled = false
				});
			},
		},
		mounted() {
			this.isUpload = this.$route.query.isUpload;
			this.formD.oneClickShipping = this.$route.query.oneClickShipping;
			if(!this.isUpload){
				this.myCart();
			}
	  },
	}
</script>

<style scoped>
.tj-btn{
	width: 100%;
	background: #e81212;
	color: #fff !important;
	padding: 15px 35px !important;
	border-radius: 30px !important;
	font-size: 14px !important;
	font-weight: 700;
	line-height: 1.5 !important;
}
.tj-btn:hover{
	background: #000 !important;
}
</style>