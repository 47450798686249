// src/store/index.js
import { createStore } from 'vuex'
 
export default createStore({
  state() {
    return {
      count: 0,
      countOrder: 0,
    }
  },
  mutations: {
		setCount(state, val) {
			state.count = val
		},
		setCountOrder(state, val) {
			state.countOrder = val
		}
  },
  actions: {},
  modules: {}
})