<template>
	<Header></Header>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
	<Footer></Footer>
	
	
</template>

<script>
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { getAction,postAction } from '@/utils/manage'
export default {
  name: 'App',
	data() {
		return {
			carts:{},
			totle:0,
			count:0,
		}
	},
  components: {
    Header,
		Footer,
  },
	methods: {
		getOrderList(){
			var that = this;
			postAction('/client/mall/businessOrderInfo/orderList', {
					"page": {
							"size": 10000,
							"current": 1
					}
			}).then((res) => {
			  if(res.code == 200){
					let records = res.data.records
					let dnum = 0
					for(var i=0;i<records.length;i++){
						if(records[i]['status']==-1 || records[i]['status']==3 || records[i]['status']==6 || records[i]['status']==7){
							dnum++
						}
					}
					this.$store.commit('setCount', dnum);
				}
			}).finally(() => {
			  
			});
		},
		getCollectiveOrderList(){
			var that = this;
			postAction('/client/mall/businessOrderInfo/collectiveOrderList', {
					"page": {
							"size": 10000,
							"current": 1
					}
			}).then((res) => {
			  if(res.code == 200){
					let records = res.data.records
					let dnum = 0
					for(var i=0;i<records.length;i++){
						if(records[i]['status']==-1 || records[i]['status']==3 || records[i]['status']==6 || records[i]['status']==7){
							dnum++
						}
					}
					this.$store.commit('setCountOrder', dnum);
				}
			}).finally(() => {
			  
			});
		},
		loadExternalScript() {
			const script1 = document.createElement('script');
			script1.src = '/assets/js/vendor.min.js';
			// 当脚本加载完成时调用回调函数
			script1.onload = () => {
				// console.log("外部JS文件加载完成");
				// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
				const script2 = document.createElement('script');
				script2.src = '/assets/js/plugins.min.js';
				// 当脚本加载完成时调用回调函数
				script2.onload = () => {
					// console.log("外部JS文件加载完成");
					// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
					const script3 = document.createElement('script');
					script3.src = '/assets/js/main.js';
					// 当脚本加载完成时调用回调函数
					script3.onload = () => {
						// console.log("外部JS文件加载完成");
						// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
					};
					// 将脚本添加到HTML头部
					document.head.appendChild(script3);
					
					// console.log("外部JS文件加载完成");
					// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
					const script4 = document.createElement('script');
					script4.src = '/assets/js/toastr.min.js';
					// 当脚本加载完成时调用回调函数
					script4.onload = () => {
						// console.log("外部JS文件加载完成");
						// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
						toastr.options.positionClass = 'toast-center-center';
					};
					// 将脚本添加到HTML头部
					document.head.appendChild(script4);
				};
				// 将脚本添加到HTML头部
				document.head.appendChild(script2);
			};
			// 将脚本添加到HTML头部
			document.head.appendChild(script1);
		}
	},
	mounted() {
    // 等待DOM元素加载完成后再加载外部JS文件
    this.loadExternalScript();
		// if(localStorage.getItem('atoken')){
		// 	this.getOrderList();
		// 	// 集采专区
		// 	// this.getCollectiveOrderList()
		// }
  },
}
</script>
<style>
body{
	/* min-width: 1250px; */
}
.toast-center-center {
	position: fixed;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.cart-button-wrapper {
  position: fixed;
  bottom: 20px; /* 根据需求调整距离底部的距离 */
  right: 20px; /* 根据需求调整距离右侧的距离 */
  z-index: 1000; /* 确保购物车按钮浮于其他元素之上 */
}
</style>
