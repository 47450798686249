<template>
	<div class="page-breadcrumb" style="padding: 41px;"></div>
	<el-skeleton
		style="width: 100%; display: flex;"
		:loading="loading"
		animated
		:throttle="500"
		class="row"
	>
		<template #template>
			<div class="product-details">
				<div class="container">
					<div class="row">
						<div class="col-md-5">
							<div class="product-gallery-box product-gallery-box--default m-b-60">
								<el-skeleton-item variant="image" style="width: 100%; height: 400px" />
							</div>
						</div>
						<div class="col-md-7">
							<div class="product-details-box m-b-60">
								<el-skeleton-item variant="p" style="width: 60%;height: 40px" />
								<el-skeleton-item variant="p" style="width: 50%;height: 30px" />
								<br/>
								<el-skeleton-item variant="p" style="width: 50%;height: 30px" />
								<br/>
								<el-skeleton :rows="5" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	
		<main id="main-container" class="main-container">
			<div id="app" class="product-details">
				<div class="container">
					<div class="row">
						<div class="col-md-5">
							<!-- <el-carousel height="200px">
								<el-carousel-item v-for="(item, index) in lbs" :key="index">
									<img :src="'/XYZ/systemConfig/static/' + item" alt="Image" class="carousel-image">
								</el-carousel-item>
							</el-carousel> -->
							<div class="product-gallery-box product-gallery-box--default m-b-60">
								<div class="product-image--large product-image--large-horizontal" style="height: 613px;"><img class="img-fluid" 
										:src="'/XYZ/systemConfig/static/' + imgName"
										:data-zoom-image="'/XYZ/systemConfig/static/' + imgName" alt=""></div>
								<div id="gallery-zoom" class="product-image--thumb product-image--thumb-horizontal pos-relative">
									<a v-for="(item, index) in lbs" :key="index" @click="check(item)" class="zoom-active" 
										:data-image="'/XYZ/systemConfig/static/' + item"
										:data-zoom-image="'/XYZ/systemConfig/static/' + item"><img class="img-fluid"
										:src="'/XYZ/systemConfig/static/' + item" alt=""></a></div>
							</div>
						</div>
						<div class="col-md-7">
							<div class="product-details-box m-b-60">
								<h5 class="title title--normal m-b-20">{{ info.name }}</h5>
								<el-tabs type="border-card" v-model="activeName" v-if="info.oneClickShipping == 1" @tab-click="handleClick" :class="[activeName == 1 ? 'is-selected' : 'un-selected']">
									<el-tab-pane label="批发" :name="0"  v-loading="tabLoading">
										<div class="product__price">
											<span class="product__price-reg"><span style="color:red">供货价：￥{{ activeName == 0 ? 
												wholesaleDecimal : oneWholesaleDecimal }}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;
											<p class="product__price-reg">建议零售价：<span style="font-weight: bold;">￥{{ 
												activeName == 0 ? intervalDecimal : oneIntervalDecimal }}</span></p>
										</div>
										<div class="product__desc m-t-25 m-b-30">
											<p>{{ info.materialDetails }}</p>
										</div>
										<div  v-if="detailList[0]['sku']" id="choose-attr-1" class="li p-choose" data-type="套装" data-idx="0">
										<div class="dt ">选择规格 </div>
											<div class="dd" style="display: flex;">
												<div v-for="(item,index) in detailList" :key="index" :class="[item.id == materialExtendId ? 'selected' : '']"  @click="choose(item)" class="item  " data-sku="57704110363" >
													<b></b>
													<a href="#" @click.prevent style="text-align: center;padding: 0 30px;"><i>{{ item.sku }}</i>
													</a>
												</div>
											</div>
										</div>
									</el-tab-pane>
									<el-tab-pane label="代发"  v-loading="tabLoading">
										<div class="product__price" :name="1">
											<span class="product__price-reg"><span style="color:red">供货价：￥{{ activeName == 0 ? 
												wholesaleDecimal : oneWholesaleDecimal }}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;
											<p class="product__price-reg">建议零售价：<span style="font-weight: bold;">￥{{ 
												activeName == 0 ? intervalDecimal : oneIntervalDecimal }}</span></p>
										</div>
										<div class="product__desc m-t-25 m-b-30">
											<p>{{ info.materialDetails }}</p>
										</div>
										<div  v-if="detailList[0]['sku']" id="choose-attr-1" class="li p-choose" data-type="套装" data-idx="0">
										<div class="dt ">选择规格 </div>
											<div class="dd" style="display: flex;">
												<div v-for="(item,index) in detailList" :key="index" :class="[item.id == materialExtendId ? 'selected' : '']"  @click="choose(item)" class="item  " data-sku="57704110363" >
													<b></b>
													<a href="#" @click.prevent style="text-align: center;padding: 0 30px;"><i>{{ item.sku }}</i>
													</a>
												</div>
											</div>
										</div>
									</el-tab-pane>
								</el-tabs>
								<div v-else>
									<div class="product__price" :name="1">
										<span class="product__price-reg"><span style="color:red">供货价：￥{{ activeName == 0 ? 
											wholesaleDecimal : oneWholesaleDecimal }}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;
										<p class="product__price-reg">建议零售价：<span style="font-weight: bold;">￥{{ 
											activeName == 0 ? intervalDecimal : oneIntervalDecimal }}</span></p>
									</div>
									<div class="product__desc m-t-25 m-b-30">
										<p>{{ info.materialDetails }}</p>
									</div>
									<div  v-if="detailList[0]['sku']" id="choose-attr-1" class="li p-choose" data-type="套装" data-idx="0">
									<div class="dt ">选择规格 </div>
										<div class="dd" style="display: flex;">
											<div v-for="(item,index) in detailList" :key="index" :class="[item.id == materialExtendId ? 'selected' : '']"  @click="choose(item)" class="item  " data-sku="57704110363" >
												<b></b>
												<a href="#" @click.prevent style="text-align: center;padding: 0 30px;"><i>{{ item.sku }}</i>
												</a>
											</div>
										</div>
									</div>
								</div>
								<div class="product-var p-tb-30">
									<div style="clear: both;height: 20px;"></div>
									<div class="product-quantity product-var__item"><span class="product-var__text">数量</span>
										<div class="product-quantity-box">
											<div class="quantity">
												<input v-model="quantity" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" type="number" min="1" max="9" step="1" value="1" style="width: 100px;">
												<div class="quantity-nav">
													<div @click="up" class="quantity-button quantity-up"><i class="fal fa-plus"></i></div>
													<div @click="down" class="quantity-button quantity-down"><i class="fal fa-minus"></i></div>
												</div>
											</div>
											<button :disabled="disabled" @click="addCart()"
												class="btn btn--box btn--radius btn--small btn--black btn--black-hover-red btn--uppercase btn--weight m-l-20">加入购物车</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product-details-tab-area">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-70 nav">
								<li>
									<a class="nav-link active" data-toggle="tab" href="#product-desc">商品详情</a>
								</li>
							</ul>
						</div>
						<div class="col-md-12">
							<div class="product-details-tab-box">
								<div class="tab-content">
									<div class="tab-pane show active" id="product-desc">
										<template v-for="(item, index) in imgNameDetails" :key="index">
											<img :src="'/XYZ/systemConfig/static/' + item" alt="" style="width: 100%;">
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</el-skeleton>
</template>

<script>
import { getAction, postAction } from '@/utils/manage'
export default {
	name: "ProductDetails",
	data() {
		return {
			atoken: localStorage.getItem('atoken'),
			info: {},
			detail: {},
			lbs: [],
			imgNameDetails: [],
			imgName: '',
			quantity: 1,
			materialId: 0,
			materialExtendId: 0,
			disabled: false,
			detailList:[
				{
					barCode:"",
					intervalDecimal: 0,
					commodityUnit: "",
					id: 0,
					materialId: 0,
					sku: "",
					wholesaleDecimal: 0
				}
			],
			wholesaleDecimal: 0, // 价格
			intervalDecimal: 0, //建议零售价
			oneWholesaleDecimal: 0, // 价格
			oneIntervalDecimal: 0, //建议零售价
			activeName: 0,
			loading: false,
			tabLoading:false,
		}
	},
	methods: {
		handleClick(tab, event) {
			this.tabLoading = true
			setTimeout(() => {
				this.tabLoading = false
			}, 500)
		},
		addCart() {
			var that = this;
			if ( that.quantity < 1 ) {
				toastr.error("加入购物车失败,数量必须大于0！");
				return
			}
			that.disabled = true
			postAction('/client/mall/businessShoppingCart/addCart', {
				"materialId": that.materialId,
				"quantity": that.quantity,
				"materialExtendId": that.materialExtendId,
				"oneClickShipping": that.activeName
			}).then((res) => {
				that.disabled = false
				if (res.code == 200) {
					toastr.success("加入购物车成功！")
				} else {
					toastr.error("加入购物车失败！");
				}
			}).finally(() => {
				that.disabled = false
			});
		},
		getInfo(id) {
			var that = this;
			that.loading = true;
			getAction('/client/mall/material/getInfo/' + id, '').then((res) => {
				if (res.code == 200) {
					that.info = res.data;
					that.materialId = res.data.id;
					that.detailList = res.data.detailList
					that.materialExtendId = res.data.detailList[0]['id'];
					that.wholesaleDecimal = res.data.detailList[0]['wholesaleDecimal'];
					that.intervalDecimal = res.data.detailList[0]['intervalDecimal'];
					that.oneWholesaleDecimal = res.data.detailList[0]['oneWholesaleDecimal'];
					that.oneIntervalDecimal = res.data.detailList[0]['oneIntervalDecimal'];
					if (res.data.imgName) {
						var carouselStr = res.data.imgName;
						if(res.data.imgNameCarousel){
							carouselStr = carouselStr + ',' + res.data.imgNameCarousel;
						}
						that.lbs = carouselStr.split(",");
					}
					// if (res.data.imgNameCarousel) {
					// 	that.lbs = res.data.imgNameCarousel.split(",");
					// }
					if (res.data.imgNameDetails) {
						that.imgNameDetails = res.data.imgNameDetails.split(",");
					}
					// that.imgName = that.lbs[0]
					that.imgName = res.data.imgName
					that.loadExternalScript();
					that.loading = false;
				}else {
					toastr.error(res.msg);
					setTimeout(() => {
						window.location.href = '/shopmall';
					}, 1000);
				}
			}).finally(() => {

			});

		},
		check(item) {
			this.imgName = item
		},
		choose(item) {
			this.materialExtendId = item.id
			this.wholesaleDecimal = item.wholesaleDecimal
			this.intervalDecimal = item.intervalDecimal
			this.oneWholesaleDecimal = item.oneWholesaleDecimal
			this.oneIntervalDecimal = item.oneIntervalDecimal
		},
		up(){
			this.quantity++
		},
		down(){
			if(this.quantity>1){
				this.quantity--
			}
		},

		loadExternalScript() {
			// console.log("外部JS文件加载完成");
			// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
			const script3 = document.createElement('script');
			script3.src = '/assets/js/main1.js';
			// 当脚本加载完成时调用回调函数
			script3.onload = () => {
				// console.log("外部JS文件加载完成");
				// 这里可以写入需要执行的其他操作或者调用外部JS提供的功能
			};
			// 将脚本添加到HTML头部
			document.head.appendChild(script3);
		}
	},
	created: function () {
		const id = this.$route.query.id;
		this.getInfo(id);

	},
	mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style>



#choose-attr-1 {
    line-height: 40px;
}
.li {
    margin-bottom: 3px;
    display: block;
}
.itemInfo-wrap .dt {
    float: left;
    padding-left: 10px;
    font-family: simsun;
    color: #999;
}
div {
    display: block;
}
#choose-attr-1 {
    line-height: 40px;
}
.itemInfo-wrap .dd {
    margin-left: 70px;
}
#choose-attr-1 .item {
    background-color: #f7f7f7;
}
.li .item {
    float: left;
    color: #666;
    background-color: #fff;
    margin-right: 7px;
    margin-bottom: 4px;
}
#nav-2014 .new-tab, .EDropdown, .EDropdown .head, .itemInfo-wrap .summary, .li .item, .m-content .mt, .plist-pop li, .sp-category dt {
    position: relative;
}
#choose-attr-1 .item a {
    padding: 0;
}
.li .item.hover a, .li .item.selected a, .li .item:hover a {
    border: 1px solid #e3393c;
    color: #666;
}
.li .item a {
    border: 1px solid #ccc;
    padding: 0 13px;
    display: block;
    white-space: nowrap;
}
img {
    border: 0;
    vertical-align: middle;
}

img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
#choose-attr-1 .item a i {
    margin: 0 8px;
}
em, i, u {
    font-style: normal;
}
#choose-attr-1 {
    line-height: 40px;
}

.product-image--large .img-fluid {
    height: 613px;
}
.zoom-active img {
    height: 144px;
}

::v-deep .el-tabs__nav-scroll{
	width:50%;
	margin:0 auto
}

.el-tabs__active-bar {
	background-color:red !important
}
.el-tabs__item .is-active {
    color: red !important;
		font-size: 15px;
    font-weight: 600;
}
.el-tabs__item:hover{
	color: red !important;
}

.el-tabs__nav-scroll .el-tabs__nav{
	width: 100%;
	justify-content: space-between;
}
.el-tabs__nav-scroll .el-tabs__nav .el-tabs__item{
	width: 50%;
	font-size: 16px;
}
.product__price{
	margin-top: 10px;
}
.is-selected{
	background-color: #F5F7FA !important;
}
.is-selected .is-active{
	background-color: #F5F7FA !important;
	/* border-right-color: transparent !important; */
	border-left-color: transparent !important
}
.is-selected #tab-1{
	border-right-color: transparent !important;
}
#tab-0{
	background-color: #ffffff;
}
</style>