<template>
	<div class="page-breadcrumb"  style="padding: 41px;">
		<!-- <div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="page-breadcrumb__menu">
						<li class="page-breadcrumb__nav"><a href="/">首页</a></li>
						<li class="page-breadcrumb__nav active">联系我们</li>
					</ul>
				</div>
			</div>
		</div> -->
	</div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				
				<div class="col-lg-4 col-md-5">
					<div class="contact-info-wrap gray-bg m-t-40">
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-phone-alt"></i></div>
							<div class="contact-info-dec"><a href="tel://139 9559 9146">139 9559 9146</a><a
									href="tel://155 2768 6267">155 2768 6267</a></div>
						</div>
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-globe-asia"></i></div>
							<div class="contact-info-dec"><a href="mailto://miaoqin@aozijiapin.com">miaoqin@aozijiapin.com</a><a
									href="mailto://haolong@aozijiapin.com">haolong@aozijiapin.com</a></div>
						</div>
						<div class="single-contact-info">
							<div class="contact-icon"><i class="fas fa-map-marker-alt"></i></div>
							<div class="contact-info-dec"><span>湖北省武汉市洪山区高新大道776号</span><span>华翔中心10楼</span></div>
						</div>
						
					</div>
				</div>
				<div class="col-lg-8 col-md-7">
					<div class="contact-form gray-bg m-t-40">
						<div class="section-content">
							<h5 class="section-content__title">取得联系</h5>
						</div>
						<form class="contact-form-style" id="contact-form" action="javascript:;" method="POST">
							<div class="row">
								<div class="col-lg-6">
									<div class="form-box__single-group"><input type="text" placeholder="名字"
											required=""></div>
								</div>
								<div class="col-lg-6">
									<div class="form-box__single-group"><input type="email" placeholder="联系方式"
											required=""></div>
								</div>
								<div class="col-lg-12">
									<div class="form-box__single-group"><input type="text" placeholder="主题"
											required=""></div>
								</div>
								<div class="col-lg-12">
									<div class="form-box__single-group"><textarea rows="10"
											placeholder="你的信息" required=""></textarea></div><button
										class="btn btn--box btn--small btn--black btn--black-hover-red btn--uppercase font--bold m-t-30"
										type="submit">发送</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
</script>

<style>
</style>