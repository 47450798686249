import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js' // VueX存储路径
import { formatToThousand } from '/public/assets/js/utilities';


// import ElementPlus from 'element-plus'
// import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '../public/assets/css/custom-element-plus-theme.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import * as http from '/src/utils/http.js'
import md5 from "js-md5";

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(router)
app.use(store) // 将VueX注册到应用程序中
app.use(ElementPlus, {
  locale: zhCn,
})
app.config.globalProperties.$http = http;
app.config.globalProperties.$md5 = md5;


app.mixin({
  methods: {
    $formatToThousand(value) {
      return formatToThousand(value);
    }
  }
});

app.mount('#app')
