<template>
	<div class="table-content table-responsive cart-table-content m-t-30" style="overflow: hidden;">
		<div class="pagination">
			<el-row style="width: calc(100% - 21px); text-align: center">
				<div style="width: 10%;">主订单号</div>
				<div style="width: 10%;">采购类型</div>
				<div style="width: 10%;">订单类型</div>
				<div style="width: 10%;">联系人</div>
				<div style="width: 10%;">联系电话</div>
				<div style="width: 20%;">地址</div>
				<div style="width: 10%;">总价</div>
				<div style="width: 10%;">状态</div>
				<div style="width: 10%;">操作</div>
			</el-row>
		</div>
		<el-collapse v-model="activeName" accordion class="custom-collapse">
			<el-collapse-item v-for="(item,index) in records" :key="index" :name="index">
				<template #title>
					<el-row class="row-bg" style="width: 100%;">
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.masterNumber}}</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.oneClickShipping == 1 ? '代发' : '批发'}}</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.orderType == 'JC' ? '集采' : '商城'}}</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.linkName}}</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.linkTel}}</div></div>
						<div class="tb-item" style="width: 20%;"><div class="tb-item-text">{{item.address}}</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{item.totalPrice}}元</div></div>
						<div class="tb-item" style="width: 10%;"><div class="tb-item-text">{{statusText(item.status)}}</div></div>
						<div class="tb-item" style="width: 10%;">查看详情</div>
					</el-row>
				</template>
				<div style="padding: 0 40px 0 40px; overflow: hidden;">
					<el-collapse v-model="subActiveName" accordion class="custom-collapse">
						<el-collapse-item v-for="(subitem,subindex) in item.splitOrderVos" :key="subindex" :name="subindex" :class="[subindex == item.splitOrderVos.length-1 ? 'collapse-item' : '']">
							<template #title >
								<el-row class="row-bg" style="width: 100%;">
									<div class="tb-item-sub tb-item-sub-title" style="width: 10%;">单号</div>
									<div class="tb-item-sub" style="width: 15%;">{{ subitem.number }}</div>
									<div class="tb-item-sub tb-item-sub-title" style="width: 10%;">总价：</div>
									<div class="tb-item-sub" style="width: 10%;">{{ subitem.totalPrice }}元</div>
									<div class="tb-item-sub tb-item-sub-title" style="width: 10%;">状态</div>
									<div class="tb-item-sub" style="width: 15%;">{{ statusText(subitem.status) }}</div>
									<div class="tb-item-sub" style="width: 30%;display: flex;justify-content: flex-end;padding-right: 10px;">
										<button v-if="subitem.status==-1" @click.stop="getDht(subitem.id)" class="new-btn btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">下载模板</button>
										<button v-if="subitem.status>-1 && subitem.fileName" @click.stop="seeHt(subitem.fileName)" class="new-btn btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">查看合同</button>
										<button v-if="subitem.status==-1" @click.stop="uploadFile(subitem.id,1)" class="new-btn btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">上传合同</button>
										<button v-if="subitem.status==3 || ( subitem.status==7 && ( subitem.payType == 'WEEK' || subitem.payType == 'MONTH' || item.oneClickShipping == 1 ) )" @click.stop="uploadFile(subitem.id, 3)" class="new-btn btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">上传支付凭证</button>
										<button v-if="subitem.status==6" @click.stop="confirmReceipt(subitem.id)" class="new-btn btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">确认收货</button>
									</div>
								</el-row>
							</template>
							<div style="padding: 0 40px 0 40px;">
								<el-table :data="subitem.voList" border style="width: 100%" class="scrollbar-container">
									<el-table-column type="index" label="#" width="100"></el-table-column>
									<el-table-column prop="barCode" label="条码" width="200"> </el-table-column>
									<el-table-column prop="materialName" label="名称" width="230"></el-table-column>
									<el-table-column prop="standard" label="规格" width="120"> </el-table-column>
									<el-table-column prop="model" label="型号" width="120"> </el-table-column>
									<el-table-column prop="operNumber" label="数量" width="120"> </el-table-column>
									<el-table-column prop="materialUnit" label="单位" width="120"> </el-table-column>
									<el-table-column prop="unitPrice" label="单价（元）"> </el-table-column>
								</el-table>
								<div v-if="item.paymentVoucherList.length > 0">
									<div class="fileTile">支付凭证：</div>
									<div class="fileList" v-for="(paymentItem,paymentIndex) in item.paymentVoucherList" :key="paymentIndex"><a :href="paymentItem.fileUrl" target="_blank">{{ paymentItem.fileName }}</a></div>
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</el-collapse-item>
		</el-collapse>
		
	</div>
	<el-dialog
    v-model="dialogVisible"
    title="预览"
    :before-close="handleClose"
  >
		<vue-office-docx v-if="filetype=='docx'" :src="fileurl" @rendered="rendered"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="xzFile()">
          下载
        </el-button>
      </span>
    </template>
  </el-dialog>
	<el-dialog title="上传" v-model="dialogTableVisible" draggable overflow width="800">
		<el-upload
			class="upload-demo"
			ref="upload"
			drag
			action="/XYZ/systemConfig/upload"
			:headers="headersObj"
			:data="uploadData"
			:limit="1"
			:on-exceed="handleExceed"
			:on-success="handleSuccess"
			v-model:file-list="fileList"
		>
			<el-icon class="el-icon--upload"><upload-filled /></el-icon>
			<div class="el-upload__text">
				拖动文件到这 或 <em>点击上传</em>
			</div>
			<template #tip>
				<div class="el-upload__tip">
					请上传文件
				</div>
			</template>
		</el-upload>
		<el-row justify="end" style="margin-top: 20px;">
			<el-button @click="submitUpload" :disabled="disabled" type="primary" size="large">提交</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import VueOfficeDocx from '@vue-office/docx'
import { postAction,uploadAction } from '@/utils/manage'
export default {
	name: 'OrderListCom',
	data() {
		return {
			activeName: '',
			subActiveName: '',
			dialogVisible:false,
			dialogTableVisible:false,
			filetype:'',
			fileurl: '', //设置文档地址
			type: '',
			fileName: '',
			fileList: [],
			uploadData: {
				biz:'material'
			},
			disabled: false,
			records:[],
		}
	},
	props:{
		orderList:{
			type:Array,
			default:()=>[]
		},
		orderType:{
			type:String,
			default:''
		},
	},
	components:{
		VueOfficeDocx,
	},
	methods:{
		submitUpload(){
			this.orderChange(this.id, this.fileName, this.type);
		},
		uploadFile(id,type){
			this.fileList = [];
			this.id = id;
			this.type = type;
			this.dialogTableVisible = true
		},
		handleSuccess(response, file, fileList) {
			if(response.code == 200){
				this.fileName = response.data;
				this.disabled = false;
			}
		},
		handleExceed(files, fileList) {
			this.$message.error('只能上传一个文件，请删除已上传的文件后再上传新文件');
			// 或者你想保留最新的文件，可以这样操作：
			// fileList.pop(); // 删除队列中最后一个文件（旧文件）
			// fileList.push(...files); // 添加新的文件到队列
		},
		statusText(status) {
			switch (status) {
				case -1:
					return '待上传合同';
				case 0:
					return '待确认';
				case 1:
					return '待确认';
				case 2:
					return '待确认';
				case 3:
					return '待上传支付凭证';
				case 4:
					return '待发货';
				case 5:
					return '待发货';
				case 6:
					return '待确认收货';
				case 7:
					return '已收货';
				case 8:
					return '已完成';
				default:
					return '未知状态';
			}
		},
		getOrderList(){
			var that = this;
			postAction('/client/mall/businessOrderInfo/orderList', {
					"page": {
							"size": 10000,
							"current": 1
					},
					orderType: that.orderType
			}).then((res) => {
				if(res.code == 200){
					that.records = res.data.records
					let dnum=0;
					for(var i=0;i<that.records.length;i++){
						that.records[i].fileNameList = [];
						if(that.records[i].fileName){
							let fileNameArr = that.records[i].fileName.split(',');
							let obj = {}
							for(let j=0;j<fileNameArr.length;j++){
								let nameArr = fileNameArr[j].split('/');
								obj = {
									"fileName": nameArr[nameArr.length-1],
									"fileUrl": '/XYZ/systemConfig/static/' + fileNameArr[j]
								}
								that.records[i].fileNameList.push(obj);
							}
						}
						that.records[i].paymentVoucherList = [];
						if(that.records[i].paymentVoucher){
							let paymentVoucherArr = that.records[i].paymentVoucher.split(',');
							let obj = {}
							for(let j=0;j<paymentVoucherArr.length;j++){
								let nameArr = paymentVoucherArr[j].split('/');
								obj = {
									"fileName": nameArr[nameArr.length-1],
									"fileUrl": '/XYZ/systemConfig/static/' + paymentVoucherArr[j]
								}
								that.records[i].paymentVoucherList.push(obj);
							}
						}
						if(that.records[i]['status']==-1 || that.records[i]['status']==3 || that.records[i]['status']==6 || that.records[i]['status']==7){
							dnum++
						}
					}
					this.$store.commit('setCount', dnum);
				}
			}).finally(() => {
				
			});
		},
		getDht(id){
			window.open('/XYZ/client/mall/businessOrderInfo/dht?id='+id)
		},
		seeHt(fileName){
			var arr = fileName.split(".");
			this.filetype = arr[arr.length-1]
			if(this.filetype == 'docx' ){
				this.fileurl = '/XYZ/systemConfig/static/'+fileName
				this.dialogVisible = true
			}else{
				window.open('/XYZ/systemConfig/static/'+fileName, '_blank')
			}
		},
		xzFile(){
			window.open(this.fileurl, '_blank')
		},
		orderChange(id, fileName, type){
			var that = this;
			that.disabled = true
			postAction('/client/mall/businessOrderInfo/orderChange', {
					"orderId": id,
					"fileName": fileName,
					"type": type
			}).then((res) => {
				if(res.code == 200){
					toastr.success("上传成功！")
					that.fileList = [];
					that.getOrderList();
					that.dialogTableVisible = false
					// setTimeout(() => {
					// 	window.location.href = '/orderlist';
					// }, 1500); // 延迟2秒执行跳转
				}else{
					toastr.error("上传失败！")
					that.disabled = false
					// setTimeout(() => {
					// 	window.location.href = '/orderlist';
					// }, 1500); // 延迟2秒执行跳转
				}
			}).finally(() => {
				
			});
		},
		uploadFileOld(id,type){
			var that = this;
			document.getElementById('fileInput').click(); 
			document.getElementById('fileInput').addEventListener('change', function(event) {  
				var file = event.target.files[0];  
				if(!file){
					return
				}
				// 在这里处理文件上传的逻辑，例如发送到服务器等。 
				const formData = new FormData();  
				formData.append('file', file); 
				formData.append('biz', 'material');
				toastr.info('上传中，请稍候...');
				uploadAction('/systemConfig/upload', formData).then((res) => {
					if(res.code == 200){
						postAction('/client/mall/businessOrderInfo/orderChange', {
								"orderId": id,
								"fileName": res.data,
								"type": type
						}).then((res) => {
							if(res.code == 200){
								toastr.success("上传成功！")
								// that.getOrderList();
								setTimeout(() => {
									window.location.href = '/orderlist';
								}, 1500); // 延迟2秒执行跳转
							}else{
								toastr.error("上传失败！")
								setTimeout(() => {
									window.location.href = '/orderlist';
								}, 1500); // 延迟2秒执行跳转
							}
						}).finally(() => {
							
						});
					}else{
						toastr.error(res.data)
					}
				}).finally(() => {
					
				});
			});
		},
		confirmReceipt(id){
			var that = this
			postAction('/client/mall/businessOrderInfo/orderChange', {
					"orderId": id,
					"type":5
			}).then((res) => {
				if(res.code == 200){
					toastr.success("已确认！")
					that.getOrderList();
				}else{
					toastr.error("确认失败！")
				}
			}).finally(() => {
				
			});
		}
	},
	mounted() {
		this.getOrderList();
		
	},
}
</script>

<style scoped>

.custom-collapse{
	border-top: 0 !important;
}
.tb-item-sub{
	line-height: 45px;
	font-size: 15px;
	color: black;
}
.tb-item-sub-title{
	background: var(--el-fill-color-light);
	font-weight: 600;
}
.el-table thead th.el-table__cell {
    background: var(--el-fill-color-light);
}
.new-btn{
	width: 8rem !important;
	margin-right: 5px;
}
.collapse-item  .el-collapse-item__header{
	border-bottom: 0 !important;
}
.pagination{
	background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
	font-size: 15px;
  font-weight: 700;
	line-height: 80px;
}
.fileTile{
	font-size: 15px;
	font-weight: 600;
	margin-top: 10px;
}
.fileList a{
	color: #0d6efd !important;
}
</style>