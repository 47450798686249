<template>
	<header>
		<div class="header header--1  d-xl-block">
			<div class="header__middle sticky-header header__color--silver p-tb-10 d-flex align-items-center" style="z-index: 1999;">
				<div class="container">
					<div class="row align-items-center">
						<div v-if="!isMobile" class="col-xl-3 col-md-3 d-flex align-items-center d-none">
							<div  class="header__logo header__logo--1"><router-link to="/" class="header__logo-link img-responsive"><img
										class="header__logo-img img-fluid" src="assets/picture/logo1.png" alt=""></router-link></div>
						</div>
						<div class="col-xl-9 col-md-12 d-flex align-items-center justify-content-between">
							<div class="header-menu">
								<nav>
									<ul class="header__nav">
										<li class="dx-bh">
											<span  class="header__nav-item pos-relative">
												<a href="/" class="header__nav-link header__nav-link--white header__nav-link--white-hover-white">首页</a>
											</span>
										</li>
										<li class="dx-bh">
											<span  class="header__nav-item pos-relative"><a href="/shopmall"
												class="header__nav-link header__nav-link--white header__nav-link--white-hover-white">商城</a></span>
										</li>
										<li class="dx-bh">
											<span class="header__nav-item pos-relative"><a href="/orderlist"
												class="header__nav-link header__nav-link--white header__nav-link--white-hover-white">我的订单&nbsp;<span v-if="$store.state.count<0" class="wishlist-item-count wishlist-item-count--red">{{$store.state.count}}</span></a></span>
										</li>
										<li class="dx-bh">
											<span class="header__nav-item pos-relative"><a href="/orderlistfocus"
												class="header__nav-link header__nav-link--white header__nav-link--white-hover-white">集采专区&nbsp;<span v-if="$store.state.countOrder<0" class="wishlist-item-count wishlist-item-count--red">{{$store.state.countOrder}}</span></a></span>
										</li>
										<li class="dx-bh">
											<span class="header__nav-item pos-relative"><a href="/contact"
												class="header__nav-link header__nav-link--white header__nav-link--white-hover-white">联系我们</a></span>
										</li>
										
									</ul>
								</nav>
							</div>
							<ul class="header__user-action-icon">
								<li class="header-add-cart pos-relative">
									<a @click="myCart()" href="#offcanvas-add-cart__box" class="offcanvas-toggle">
										<!-- <i class="ion-bag"></i> -->
										<el-icon v-if="count>0"><ShoppingCartFull /></el-icon>
										<el-icon v-else><ShoppingCart /></el-icon>
										<!-- <span class="wishlist-item-count wishlist-item-count--red pos-absolute">{{count}}</span> -->
									</a>
								</li>
								<!-- <li class="header-user-sidebar pos-relative"><a href="#offcanvas-user" class="offcanvas-toggle"><i
											class="ion-navicon-round"></i></a></li> -->
								<el-dropdown v-if="userInfo">
									<el-button  style="background-color: transparent; border: 0px;">
										<i class="ion-navicon-round"></i>
									</el-button>
									<template #dropdown>
										<el-dropdown-menu>
											<el-dropdown-item v-for="(item,index) in userList" :key="index"><a target="_blank" @click="go(item.sysUrl)" >{{ item.sysName }}</a></el-dropdown-item>
											<el-dropdown-item ><a href="/changePassword">修改密码</a></el-dropdown-item>
											<el-dropdown-item  @click="logout()">退出登录</el-dropdown-item>
										</el-dropdown-menu>
									</template>
								</el-dropdown>
								<li style="padding: 8px  15px;" class="header-user-sidebar pos-relative">
									<div v-if="userInfo" style="color:white;" >
										{{userInfo.supplier}}
									</div>
									<div v-else style="width: 30px;">
										<a href="/login" >登录</a>
									</div>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div id="offcanvas-user" class="offcanvas offcanvas-user">
			<div class="offcanvas__top"><span class="offcanvas__top-text"></span><button class="offcanvas-close"><i
						class="fal fa-times"></i></button></div>
			<div class="offcanvas__about m-t-50">
				<div class="offcanvas__logo"><router-link to="/" class="offcanvas__logo-link"><img style="width: 200px;"
							src="assets/picture/logo1.png" alt="" class="offcanvas__logo-img"></router-link></div>
				<div class="single-contact-info">
					<div class="contact-icon" style="color: white;"><i class="fas fa-phone-alt"></i></div>
					<div class="contact-info-dec"><a style="color: white;" href="tel://139 9559 9146">139 9559 9146</a><a style="color: white;"
							href="tel://155 2768 6267">155 2768 6267</a></div>
				</div>
				<div class="single-contact-info">
					<div class="contact-icon"><i style="color: white;" class="fas fa-globe-asia"></i></div>
					<div class="contact-info-dec"><a style="color: white;" href="mailto://miaoqin@aozijiapin.com">miaoqin@aozijiapin.com</a><a style="color: white;"
							href="mailto://haolong@aozijiapin.com">haolong@aozijiapin.com</a></div>
				</div>
				<div class="single-contact-info">
					<div class="contact-icon"><i class="fas fa-map-marker-alt" style="color: white;"></i></div>
					<div class="contact-info-dec"><span style="color: white;">湖北省武汉市洪山区高新大道776号</span><span style="color: white;">华翔中心10楼</span></div>
				</div>
				<a v-if="atoken" style="width: 100%;" href="/changePassword" class="btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">修改密码</a>
				<button v-if="atoken" style="width: 100%; margin-top: 10px;" @click="logout()" class="btn btn--box btn--tiny btn--radius btn--red btn--red-hover-black btn--uppercase font--semi-bold" type="submit">退出登录</button>
			</div>
			
		</div>
		
		<div id="offcanvas-add-cart__box" class="offcanvas offcanvas-cart offcanvas-add-cart">
			<div class="offcanvas__top">
				<span class="offcanvas__top-text">
					<el-icon v-if="count>0"><ShoppingCartFull /></el-icon>
					<el-icon v-else><ShoppingCart /></el-icon>
					购物车</span>
				<button class="offcanvas-close"><i class="fal fa-times"></i></button>
			</div>
			<el-tabs v-model="activeName"  @tab-click="handleClick" stretch>
				<el-tab-pane label="批发" :name="0">
					
				</el-tab-pane>
				<el-tab-pane label="代发" :name="1">
					
				</el-tab-pane>
			</el-tabs>
			<ul class="offcanvas-add-cart__menu">
				<li v-for="(item,index) in carts.records" :key="index" class="offcanvas-add-cart__list pos-relative d-flex align-items-center justify-content-between " style="margin-bottom: 0;border-top:none">
					<div class="offcanvas-add-cart__content d-flex align-items-start m-r-10">
						<div class="offcanvas-add-cart__img-box pos-relative"><a :href="'/productdetails?id='+item.materialId"
								class="offcanvas-add-cart__img-link img-responsive"><img style="width: 150px;" :src="'/XYZ/systemConfig/static/'+item.imgName"
									alt="" class="add-cart__img"></a>
									<!-- <span class="offcanvas-add-cart__item-count pos-absolute">{{item.quantity}}x</span> -->
						</div>
						<div class="offcanvas-add-cart__detail"><a :href="'/productdetails?id='+item.materialId"
							class="offcanvas-add-cart__link">{{item.materialName}}</a>
							<span>{{ item.sku }}</span>
							<span class="offcanvas-add-cart__price">￥{{ item.price }} 
								<span style="color: red;font-size: 13px;display: contents"> X {{item.quantity}}</span>
							</span>
						</div>
					</div>
					<button @click="deleteCart(item.shoppingCartId)" class="offcanvas-add-cart__item-dismiss"><i class="fal fa-times"></i></button>
				</li>
				
			</ul>
			<div class="offcanvas-add-cart__checkout-box-bottom" style="border-top: 1px solid #e0e0e0;">
				<ul class="offcanvas-add-cart__checkout-info">
					<li class="offcanvas-add-cart__checkout-list"><span
							class="offcanvas-add-cart__checkout-left-info">总价</span><span
							class="offcanvas-add-cart__checkout-right-info">￥{{ totle }}</span></li>
				</ul>
				<div v-if="carts.records.length > 0" class="offcanvas-add-cart__btn-checkout"><a :href="'/checkout?oneClickShipping='+oneClickShipping" 
						class="btn btn--block btn--radius btn--box btn--gray btn--gray-hover-red btn--large btn--uppercase font--bold offcanvas-close">结    账</a>
				</div>
			</div>
		</div>
		<div ref="cartButtonWrapper" class="cart-button-wrapper">
			<a @click="myCart()" href="#offcanvas-add-cart__box" class="offcanvas-toggle" style="font-size: 24px;">
				<el-icon v-if="count>0"><ShoppingCartFull /></el-icon>
				<el-icon v-else><ShoppingCart /></el-icon>
				<!-- <span class="wishlist-item-count wishlist-item-count--red pos-absolute">{{count}}</span> -->
			</a>
		</div>
		<div class="offcanvas-overlay"></div>
	</header>
	
</template>

<script>
	import { getAction,postAction,deleteAction } from '@/utils/manage'
	export default {
		name:"Header",
		data:function(){
			return{
				products:[],
				supplier: localStorage.getItem('supplier'),
				atoken: localStorage.getItem('atoken'),
				searchName: '',
				isMobile: true,
				activeName: 0,
				carts:{
					records: []
				},
				totle: '0.00',
				count: 0,
				oneClickShipping: 0,
				userList: [],
				userInfo: '',
			}
		},
		methods: {
			go(sysUrl){
				var that = this;
				getAction('/client/mall/user/getTickets', '').then((res) => {
				  if(res.code == 200){
						var newWindow = window.open(sysUrl+"?ticket="+encodeURIComponent(res.data), "_blank");
					}
				}).finally(() => {
				  
				});
			},
			getUrlList(){
				var that = this;
				getAction('/client/mall/user/getUrlList', '').then((res) => {
				  if(res.code == 200){
						that.userList = res.data
					}
				}).finally(() => {
				  
				});
			},
			getUserInfo(){
				var that = this;
				getAction('/client/mall/user/userInfo', '').then((res) => {
				  if(res.code == 200){
						that.getUrlList()
						that.userInfo = res.data
					}
				}).catch(e => {
				  that.userInfo = ''
				}).finally(() => {
				  
				});
			},
			handleClick(tab, event) {
				this.carts = {
					records: []
				};
				this.totle = '0.00'
				this.oneClickShipping = tab.props.name;
				this.myCart();
			},
			myCart(){
				var that = this;
				postAction('/client/mall/businessShoppingCart/myCart', {
						"size": 100,
						"oneClickShipping": that.oneClickShipping,
						"current":1
					}).then((res) => {
					if(res.code == 200){
						that.totle = res.data.totalPrice
						that.carts = res.data.mallBusinessShoppingCartVoIPage;
						that.count = res.data.mallBusinessShoppingCartVoIPage.records.length;
						
					}
				}).finally(() => {
					
				});
			},
			deleteCart(shoppingCartId){
				var that = this;
				getAction('/client/mall/businessShoppingCart/deleteCart?shoppingCartIds='+shoppingCartId, '').then((res) => {
				  if(res.code == 200){
						toastr.success("删除成功！")
						that.myCart()
					}
				}).finally(() => {
				  
				});
			},
			logout(){
				getAction('/user/logout').then((res) => {
				  if(res.code == 400){
						localStorage.removeItem('atoken')
						localStorage.removeItem('supplier')
						toastr.success("退出成功！")
						window.location.href = '/';
					}
				}).finally(() => {
				  
				});
			},
			checkDeviceType() {
				// 使用一个函数来检测当前设备是否为手机
				const userAgent = navigator.userAgent;
				if (
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(userAgent)
				) {
					this.isMobile = true; // 如果是手机，则设置为true
				} else {
					this.isMobile = false; // 如果不是手机，则设置为false
				}
				this.isMobile = window.innerWidth <= 1100
			},
			changePassword(){
				this.$router.push('/changePassword')
			},
		},
		created() {
			this.checkDeviceType();
		},
		mounted: function() {
			if(this.atoken){
				this.getUserInfo();
			}
		}
	}
</script>

<style scoped>
.whi{
	color: #fff !important;
}
.header__logo--1 {
    width: 50%;
    margin-right: 105px;
}
.header__user-action-icon{
	width: 35%;
}
.header-menu{
	width: 65%;
}
.dx-bh{
	/* width: 25%; */
	margin-left: 6%;
	font-size: 1.3rem;
}
</style>