<template>
	<div class="page-breadcrumb"  style="padding: 41px;">
		
	</div>
	<main id="main-container" class="main-container">
		<div class="container" id="app">
			<div class="row flex-column-reverse flex-lg-row">
				<div class="col-lg-3">
					<div class="sidebar">
						<div class="sidebar__widget">
							<div class="sidebar__box">
								<h5 class="sidebar__title">产品分类</h5>
							</div>
							<el-menu
								default-active="1"
								class="el-menu-vertical-demo"
								@open="handleOpen"
								@close="handleClose"
							>
								<el-menu-item index="0">
									<span class="sidebar__menu-link oneSider" @click="searchMaterial()">全部</span>
								</el-menu-item>
								<el-sub-menu :index="index+1" v-for="(item, index) in categoryList" :key="index">
									<template #title >
										<span class="oneSider" @click="searchMaterial(item.id,'')">{{item.title}}</span>
									</template>
									<el-menu-item :index="(index+1)+'-'+(index1+1)" v-for="(subitem,index1) in item.children" :key="index1">
										<span class="sidebar__menu-link" @click="searchMaterial(subitem.id,'')">{{ subitem.title }}</span>
									</el-menu-item>
								</el-sub-menu>
							</el-menu>
						</div>
					</div>
				</div>
				<div class="col-lg-9">
					<div class="sort-box m-b-40">
						<div style="display: flex; align-items: center;">
							<el-radio-group v-model="radio" @change="checkChange">
								<el-radio-button label="全部"></el-radio-button>
								<el-radio-button label="批发"></el-radio-button>
								<el-radio-button label="代发"></el-radio-button>
							</el-radio-group>
							<!-- <div style="margin-left: 15px;"><span>一共{{total}}个商品</span></div> -->
						</div>
						<div class="sort-box__right">
							<span style="margin-right: 20px;font-size: 18px;font-weight: 600;">搜索</span>
							<el-input
								v-model="materialName"
								style="width: 240px"
								placeholder="请输入商品名称"
								@input="searchMaterialName"
								@keyup.enter.native="searchMaterialName()"
							>
								<template #prefix>
									<el-icon class="el-input__icon"><search /></el-icon>
								</template>
							</el-input>
						</div>
					</div>
					<el-skeleton
						style="width: 100%; display: flex;"
						:loading="loading"
						animated
						:throttle="500"
						class="row"
					>
						<template #template>
							<div v-for="(item, index) in 3" :key="index" class="col-md-4 col-sm-6 col-12">
								<el-skeleton-item variant="image" style="width: 100%; height: 300px" />
								<div style="padding: 14px">
									<el-skeleton-item variant="h3" style="width: 50%" />
									<div>
										<el-skeleton-item variant="text" style="width: 40%" />
										<el-skeleton-item variant="text" style="width: 70%" />
									</div>
								</div>
							</div>
						</template>
						<div class="product-tab-area">
							<el-empty v-if="list.length == 0 && !loading" description="暂无商品" />
							<div class="tab-content">
								<div class="tab-pane show active shop-grid" id="sort-grid">
									<div class="row">
										<div v-for="(item, index) in list" :key="index" class="col-md-4 col-sm-6 col-12">
											<div class="product__box product__box--default">
												<div class="product__img-box"><router-link :to="'/productdetails?id='+item.id" class="product__img--link">
													<img class="product__img" :src="'/XYZ/systemConfig/static/'+item.imgName" alt=""></router-link>
												</div>
												<div class="product__content pos-relative">
													<router-link
														:to="'/productdetails?id='+item.id"
														class="product__link product__link--weight-light m-t-15">
														<el-tag v-if="item.oneClickShipping == 1" type="warning" size="small" effect="dark" style="font-weight: 700;">支持代发</el-tag>
														{{item.name}}
													</router-link>
													<div class="product__price m-t-5">
														<span class="product__price-reg"><span style="color:red">供货价：￥{{ atoken ? item.wholesaleDecimal : '???'}}</span></span>&nbsp;&nbsp;&nbsp;&nbsp;
														<p class="product__price-reg">建议零售价：<span style="font-weight: bold;">￥{{atoken ? item.intervalDecimal : '???'}}</span></p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-skeleton>
					<div class="page-pagination">
						<el-pagination
							v-model:page-size="pageSize4"
							:page-sizes="[9, 18, 36, 72]"
							:small="false"
							:disabled="false"
							:background="true"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
						/>
						<ul class="page-pagination__list" v-if="false">
							<li v-for="index in pages" :key="index" @click="changePage(index)" class="page-pagination__item"><a :class="index==current?'active':''" class="page-pagination__link  " href="javascript:void(0);">{{index}}</a></li>
							<li v-if="pages>current" @click="nextPage(index)" class="page-pagination__item"><a class="page-pagination__link" href="javascript:void(0);"><i class="ion-ios-skipforward"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</main>
	
</template>

<script>
	import { getAction,postAction } from '@/utils/manage'
	export default {
	  name: "ShopMall",
	  data() {
	    return {
				atoken: localStorage.getItem('atoken'),
				list:[],
				categoryList:[],
				pages:0,
				total:0,
				current:1,
				materialName:'',
				radio:'全部',
				oneClickShipping: '',
				pageSize4: 9,
				loading:false,
			}
	  },
		methods: {
			handleSizeChange (val){
				console.log('每页显示数量：',val);
				this.current = 1;
				this.pageSize4 = val;
				this.getMaterialList();
			},
			handleCurrentChange (val){
				console.log('页码：',val);
				this.current = val;
				this.getMaterialList();
			},
			checkChange(e){
				let oneClickShipping = ''
				if(e == '批发'){
					oneClickShipping = 0
				}
				if(e == '代发'){
					oneClickShipping = 1
				}
				this.oneClickShipping = oneClickShipping
				this.current = 1
				this.getMaterialList()
			},
			getCategoryList(){
				var that = this;
				getAction('/client/mall/material/getCategoryList?id=','').then((res) => {
				  if(res.code == 200){
						that.categoryList = res.data;
					}
				}).finally(() => {
				  
				});
			},
			getMaterialList(){
				var that = this;
				that.loading = true;
				postAction('/client/mall/material/getMaterialListPage', {
						"categoryId": that.categoryId,
						"name": that.name,
						"oneClickShipping": that.oneClickShipping,
						"page": {
							"size": that.pageSize4,
							"current": that.current
						}
					}).then((res) => {
				  if(res.code == 200){
						that.list = res.data.records;
						that.total = res.data.total;
						that.pages = res.data.pages;
						that.loading = false;
					}
				}).finally(() => {
				  
				});
			},
			searchMaterial(categoryId, name){
				this.current = 1
				this.categoryId = categoryId;
				this.name = name;
				this.getMaterialList()
			},
			searchMaterialName(){
				this.current = 1
				this.name = this.materialName;
				this.getMaterialList()
			},
			changePage(index){
				this.current = index;
				this.getMaterialList();
			},
			nextPage(){
				console.log(this.oneClickShipping);
				this.current++;
				this.getMaterialList();
			},
		},
		mounted: function() {
			this.getCategoryList();
			this.getMaterialList();
		}
	}
</script>

<style scoped>
.product__img-box{
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 115%;
	overflow: hidden;
}
.product__img{
	/* position: absolute;
	top: 0;
	left: 0;
	width: auto;
  height: 100%; */
	position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  max-width: 100%; /* 防止图片宽度超过父容器 */
  transform: translate(-50%, -50%);
}
.el-radio-button {
    --el-radio-button-checked-bg-color: #e81212 !important;
    --el-radio-button-checked-text-color: #ffffff !important;
    --el-radio-button-checked-border-color: #e81212 !important;
    --el-radio-button-disabled-checked-fill: ##f2f6fc !important;
}
.el-radio-button__inner:hover {
    color: #e81212 !important;
}
.categoryActive{
	color: #e81212 !important;
}
.categoryActive a{
	color: #e81212 !important;
}

.el-sub-menu__title{
	height: 40px !important;
}
.el-menu{
	border-right: 0px;
}
.sidebar__menu-link{
	width: 100%;
}
.oneSider{
	font-weight: 700 !important;
}

</style>