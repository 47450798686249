<template>
	<div class="page-breadcrumb"  style="padding: 41px;">
		<!-- <div class="container">
			<div class="row">
				<div class="col-12">
					<ul class="page-breadcrumb__menu">
						<li class="page-breadcrumb__nav"><router-link to="/">首页</router-link></li>
						<li class="page-breadcrumb__nav active">登录</li>
					</ul>
				</div>
			</div>
		</div> -->
	</div>
	<main id="main-container" class="main-container">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section-content m-b-40">
						<h5 class="section-content__title text-center">我的账户</h5>
					</div>
				</div>
				<div class="offset-lg-3 col-lg-6 col-12">
					<div class="login-form-container">
						<h5 class="sidebar__title">登录</h5>
						<div class="login-register-form">
							<form action="javascript:;" method="post">
								<div class="form-box__single-group"><label for="form-username">用户名 *</label>
								<input v-model="loginName" type="text" id="form-username" placeholder="用户名" required=""></div>
								<div class="form-box__single-group"><label for="form-username-password">密码
										*</label>
									<div class="password__toggle">
										<input  v-model="password" type="password" id="form-username-password" placeholder="输入密码" required=""><span @click="toggleType" data-toggle="#form-username-password" class="password__toggle--btn fa fa-fw" :class="{ 'fa-eye': !isPasswordVisible, 'fa-eye-slash': isPasswordVisible }"></span></div>
								</div>
								<div class="d-flex justify-content-between flex-wrap m-tb-20">
									<!-- <label for="account-remember">
										<input type="checkbox" name="account-remember" id="account-remember">
										<span>记住我</span>
									</label>
									<a class="link--gray" href="">忘记密码?</a> -->
								</div>
								<button @click="login" class="btn btn--box btn--small btn--radius btn--black btn--black-hover-red btn--uppercase font--semi-bold" type="submit">登录</button>
							</form>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</main>
</template>

<script>
	import { postAction, getAction } from '@/utils/manage'
	import { ElMessage } from 'element-plus'
	export default {
	  name: "login",
	  data(){
	    return{
	      loginName:'',
				password:'',
				isPasswordVisible: false,
	    }
	  },
		methods: {
			jumpLogin(ticket){
				var that = this;
				getAction('/client/mall/login/remoteLogin', {
					ticket:ticket
				}).then((res) => {
				  if(res.code == 200 && res.data.token){
						ElMessage.success({
							message: '登录成功！',
							type: 'success',
						})
						localStorage.setItem('atoken',res.data.token)
						localStorage.setItem('supplier',res.data.user.supplier)
						localStorage.setItem('payTypes',res.data.user.payTypes)
						// this.$router.push('/'); 
						window.location.href = '/';
					}else{
						ElMessage.warning({
							message: '登录失败',
							type: 'warning',
						})
					}
				}).finally(() => {
				  
				});
			},
			toggleType() {
				this.isPasswordVisible = !this.isPasswordVisible;
				const input = document.getElementById('form-username-password');
				if (this.isPasswordVisible) {
					input.type = 'text';
				} else {
					input.type = 'password';
				}
			},
			login(){
				var that = this;
				postAction('/client/mall/login/mallLogin', {
					loginName:that.loginName,
					password:this.$md5(that.password)
				}).then((res) => {
				  if(res.code == 200 && res.data.token){
						toastr.success("登录成功！")
						localStorage.setItem('atoken',res.data.token)
						localStorage.setItem('supplier',res.data.user.supplier)
						localStorage.setItem('payTypes',res.data.user.payTypes)
						// this.$router.push('/'); 
						window.location.href = '/';
					}else{
						toastr.error("登录失败！")
					}
				}).finally(() => {
				  
				});
			}
		},
		mounted: function() {
			const ticket = this.$route.query.ticket
			if(ticket){
				this.jumpLogin(ticket)
			}
		}
	}
</script>

<style>
</style>