import axios from 'axios';

// var base = window.localStorage.getItem("wwwurl");
// var base = 'http://lf.test';
var base = '/XYZ';

axios.interceptors.request.use(function(config) { // 请求拦截器
  if (localStorage.getItem('X-Access-Token')) {
    config.headers['X-Access-Token'] = localStorage['X-Access-Token'] || sessionStorage['X-Access-Token'];
		config.headers.contentType = 'application/json';
  }
  return config;
}, function(error) {
  return false;
})

axios.interceptors.response.use(function(success) {
  if (success.status == 200 || success.status == 201 || success.status == 204) {
    return success.data;
  }else{
    return false;
  }
}, function(error) {
  if( (error.response.status == 401) ){
    window.location = '/login';
  }
  return false;
})
export default {
  /**
   * POST值,以KEY和值的方式发送
   * @param url API地址
   * @param params 发送到服务器的参数
  */
  postKeyValueRequest(url, params) {
    return axios({
      method: 'post',
      url:base + url,
      data: params,
      transformRequest: [function(data) {
        var ret = '';
        for (var i in data) {
          ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
        }
        return ret;
      }],
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded'
      // }
    });
  },
  /**
   * POST值
   * @param url API地址
   * @param params 发送到服务器的参数
  */
  postRequest(url, params) {
    return axios({
      method: 'post',
      url: base + url,
      data: params
    })
  },
  /**
   * PATCH值
   * @param url API地址
   * @param params 发送到服务器的参数
  */
  patchRequest(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.patch(url, params).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /**
   * DELETE值
   * @param url API地址
  */
  deleteRequest(url) {
    return axios({
      method: 'delete',
      url: url
    })
  },
  /**
   * GET取值
   * @param url API地址
   * @param params 发送到服务器的参数
  */
  getRequest(url, params) {
    return axios({
      method: 'get',
      url: base + url,
      params: params
    })
  },
  daochuRequest(url, params) {
    return axios({
      method: 'get',
      url: base + url,
      params: params,
	  responseType:"arraybuffer"
    })
  },
  /**
   * 根据图片文件拿到图片实例
   * @param files 图片文件对象数组
   * @param callback
  */
  filesToInstances(files, callback) {
    let Width = 700; // 缩放后图片宽度,
    let Height = 0; //循环图片后画布高度
    let length = files.length; // 文件个数
    let images = Array(); // 文件实例数组
    let finished = 0; // 转换成功个数
    // 遍历图片文件数组
    files.forEach(function(file, index) {
      let reader = new FileReader();
      // 把文件读为 dataUrl
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result; // 获取图片文件的base64数据
        // 解决跨域
        // image.setAttribute('crossOrigin', 'anonymous');
        // image.src = e.target.result + '?time=' + new Date().valueOf();
        image.onload = function() {
          // 图片实例化成功后存起来
          // 图片原始尺寸
          let originWidth = this.width;
          let originHeight = this.height;
          //console.log(Width,originHeight,originWidth);
          //画布高度需要把所有图片高度相加（按照固定的宽度算出等比例的高度）
          Height = Height + Math.round(Width * (originHeight / originWidth));
          images.push({
            w: Width,
            h: Math.round(Width * (originHeight / originWidth)),
            o: image
          });
          finished++;
          if (finished === length) { // 如果全部完成转换，调用回调函数
            callback({
              h: Height,
              obj: images
            });
          }
        }
      }
    });
  },
  /**
   * 拼图
   * @param images 图片实例数组
   * @param callback
  */
  drawImages(images, callback) {
    //console.log(images);
    let y = 0; // canvas Y轴坐标
    // 缩放图片需要的canvas
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    // 图片原始高度除以宽度，得到压缩后的图片高度，
    canvas.width = 700;
    canvas.height = images.h;
    // 遍历图片实例数组
    //console.log(images);
    images.obj.forEach(function(item, index) {
      // 清除画布
      //context.clearRect(0, 0, Width, Height);
      // 图片压缩
      context.drawImage(item.o, 0, y, item.w, item.h);
      //console.log(y);
      //console.log(image);
      y = y + item.h; // Y轴坐标加上上一张图片高度
    });
    callback(canvas.toDataURL('image/jpeg', 0.9));
  }
}
