import { createRouter, createWebHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import HomeView from '../views/HomeView.vue'
import Index from '../views/Index.vue'
import ShopMall from '../views/ShopMall.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import ProductDetails from '../views/ProductDetails.vue'
import CheckOut from '../views/CheckOut.vue'
import OrderList from '../views/OrderList.vue'
import ChangePassword from '../views/ChangePassword.vue'
import OrderListFocus from '@/views/OrderListFocus.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/shopmall',
    name: 'shopmall',
    component: ShopMall
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/productdetails',
    name: 'productdetails',
    component: ProductDetails
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckOut,
    meta: {
      auth:true
    }
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    component: OrderList,
    meta: {
      auth:true
    }
  },
  {
    path: '/orderlistfocus',
    name: 'orderlistfocus',
    component: OrderListFocus,
    meta: {
      auth:true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      auth:true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  // 在这里执行你的拦截逻辑
  // 如果你想允许跳转，调用next()
  // 如果你想阻止跳转，可以不调用next()
  if(to.meta.auth && !localStorage.getItem('atoken')){
    ElMessage({
      offset: 160,
      message: '请先登录！',
      type: 'warning',
    })
    next('/login')
  }
  // 示例：检查用户是否登录，未登录则重定向到登录页
  if ((to.path == '/login.html') || (to.path == '/index.html') || (to.path == '/shop-sidebar-left.html') || (to.path == '/contact.html') || (to.path == '/shopmall.html') || (to.path == '/productdetails.html') || (to.path == '/checkout.html') || (to.path == '/orderlist.html') || (to.path == '/orderlistfocus.html') || (to.path == '/about.html') || (to.path == '/changePassword.html')) {
    next('/') // 未登录且目标路由要求认证，则跳转到登录页
  } else {
    next() // 允许跳转
  }
})

export default router
